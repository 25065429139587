<template>
  <v-overlay
    :value="show"
  >
    <v-card
      v-click-outside="close"
      width="500px"
      light
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Выгрузить архив документов</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="white text-lg-body-1 py-5">
        Скачать архив с документами {{ documents }}
      </v-card-text>

      <div class="d-flex justify-end">
        <v-btn
          dark
          color="#5CB7B1"
          class="mx-5 my-2"
          @click="download"
        >
          Скачать
        </v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>


import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from "vuex";
import {downloadFile} from "@/helpers/documents";
import {getStoreNamespaceByJournalDoc} from "@/helpers/common";

export default {
  mixins:[modalWindowMixin],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    module:{
      required:true,
      validator:(v) => typeof v === 'string' || v === null
    }
  },
  computed:{
    ...mapGetters({
      selected:'buffer/getDocuments',
    }),
    documents(){
      return this.selected.join(', ')
    }
  },
  methods:{
    download(){
      const namespace = getStoreNamespaceByJournalDoc(this.module)
      this.$store.dispatch(`${namespace}/getXmlPackage`, this.selected).then((res) => {
        downloadFile({ data: res.data, type: "application/zip", name:'declarations.zip'});
        this.close()
      }).catch(() => {
        this.$snackbar({text:'Ошибка',color:'red',top:false})
      })
    }
  }
}
</script>
